body {
    margin: 0;
    padding: 0;
    font-family: fot-udkakugo-large-pr6n, sans-serif;
}

.margin0{
    margin:0;
}
code {
    font-family: fot-udkakugo-large-pr6n, sans-serif;
}

.margin1 {
    margin: 1rem;
}

.gap0 {
    gap: 0;
}

.gap3 {
    gap: 3rem;
}

.none {
    display: none;
}

.swiper-slide {
    pointer-events: none;
}

.swiper-slide-active {
    pointer-events: all;
}

.font-maru {
    font-family: zen-maru-gothic, sans-serif !important;
}

.font-kirigirisu {
    font-family: "ab-kirigirisu", sans-serif;
    text-decoration-color: red !important;
}

.has-large-font-size {}

.sp-none {
    @media screen and (max-width:781px) {
        display: none;
    }
}

h2 {
    font-size: clamp(18px, 5vw, 34px);
    line-height: 1.4;
    text-underline-offset: 10px;
}

h3 {
    margin: 0;
}

p {
    line-height: 1.6;
    text-underline-offset: 10px;
}

a {
    text-decoration: none;
    color: inherit;
}

.arrow {
    position: relative;
    padding-bottom: 20px;

    &::before {
        content: "▼";
        display: block;
        color: red;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: -25px;
    }
}

.first-slider {
    display: flex;
    width: 100%;
    align-items: left center;

    @media screen and (max-width:1100px) {
        flex-direction: column;
    }

    &__left {
        width: 75%;

        @media screen and (max-width:1100px) {
            width: calc(100% - 2vw);
            padding-right: 1vw;
        }

        .swiper {
            width: 100%;

            img {
                width: 100%;
            }
        }
    }

    &__right {
        width: clamp(200px, 25%, 350px);
        padding: 2vw;

        @media screen and (max-width:1100px) {
            left: 50%;
            position: relative;
            transform: translate(-50%, 0px);
        }

        img {
            width: 100%;
            position: relative;
            top: 50%;
            transform: translate(0, -50%);

            @media screen and (max-width:1100px) {
                top: initial;
                transform: initial;
            }
        }
    }
}

.wp-block-group.wrap {
    max-width: 1000px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 1rem;
}

.inline-block-center {
    display: inline-block;
    left: 50%;
    transform: translate(-50%, 0);
    position: relative;

}

.first-view {
    &__head {
        justify-content: right !important;

        @media screen and (max-width:1100px) {
            justify-content: space-between !important;
        }
    }

    &__menu {
        padding: 2vw;
    }

    &__menu-list {
        gap: 1vw;

        @media screen and (max-width:1100px) {
            gap: 2vw;
        }
    }

    &__menu-item {
        &--text {
            @media screen and (max-width:1100px) {
                display: none;
            }
        }
    }

    &__link-title {
        display: none;

        @media screen and (max-width:1100px) {
            display: block;
        }
    }

    &__header-title {
        width: 400px;

        @media screen and (max-width:600px) {
            width: 200px;

        }
    }
}

.kennobi-content {
    padding: 40px 0;
    position: relative;

    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 500px;
        position: absolute;
        top: -500px;
        left: 0;
        z-index: -1;
        background-position: bottom;
        background-size: 100%;
        background-image: url(../../images/yellow-back-top.svg);
        background-repeat: no-repeat;
    }

    h2 {
        margin: 5px 0 0;
        letter-spacing: 0.2em;

    }

    position: relative;

    &::after {
        content: " ";
        display: block;
        width: 100%;
        height: calc(100% - 100px);
        position: absolute;
        top: 0;
        background-color: #D9E021;
        left: 0;
        z-index: -1;
    }
    &__new{
        font-size:1.3rem;
        width: 70px;
        height:70px;
        border-radius: 50%;
        background-color:#FF0000;
        text-align: center;
        line-height: 70px;
        font-weight: bold;
        color:#fff;
        font-family: zen-maru-gothic, sans-serif !important;
        position: absolute;
        z-index: 90;
        right:-5px;
        top:-10px;
    }
    &__list-slider {
        overflow: hidden;
        padding: 50px 0px;
        display: none;

        @media screen and (max-width:480px) {
            display: block;

        }
    }

    &__list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        max-width: 1300px;
        gap: 2vw;
        position: relative;
        left: 50%;
        transform: translate(-50%);
        justify-content: center;
        margin-top: 2vw;

        @media screen and (max-width:480px) {
            display: none;
        }
    }

    &__item {
        width: 100%;
        max-width: 360px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 10px 10px 30px #00000063;
        min-height: 400px;
        position: relative;
        @media screen and (max-width:480px) {}
    }

    &__image {
        position: relative;
        width: 100%;
        overflow: hidden;
        border-radius: 15px;
        img {
            width: 100%;
        }
    }

    &__content {
        padding: clamp(20px, 1.5vw, 30px);
        min-height: 150px;

        h3 {
            margin: 0;
            font-size: 1.4rem;
            font-weight: bold;
            line-height: 1.4;

            @media screen and (max-width:480px) {
                font-size: 1rem;
            }
        }

        p {
            line-height: 1.4;

            @media screen and (max-width:480px) {
                font-size: 0.8rem;
            }
        }
    }

    &__comming-soon {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 99;
    }
}

.swiper.swiper-container {
    .swiper-wrapper {
        .swiper-slide {
            transition-duration: 2000;
            transition: 0s;
        }
    }
}

.column {
    background: url("../../images/culmn-background.png");
    background-repeat: repeat;
    padding: 100px 0;
    margin-top: -100px;

    article {
        width: calc(50% - 5vw);
        padding: 1vw;
        position: relative;
        @media screen and (max-width:450px) {
            width: calc(100% - 10vw);
        }
        &:nth-child(1) {
            width: 100%;
            max-width: 700px;
            margin-bottom:40px;
        }

        time {
            width: 100%;
            text-align: right;
            display: block;
        }
    }
    &__top{
        margin-top:-80px;
    }
    &__new{
        background-image: url("../../images/column__new.svg");
        background-repeat: no-repeat;
        background-size: 100% auto;
        display: block;
        width: 100px;
        height:50px;
        position: absolute;
        top: 20px;
        left:-20px;
        @media screen and (max-width:460px) {
            top:10px;
        }
    }

    &__dummy{
        height:30px;
        width: 100px;
    }

    &__dummy2{
        height:30px;
        width: 150px;
    }
    &__content {
        h3 {
            font-size: 1.6rem;
            @media screen and (max-width:450px) {
                font-size:1.4rem;                
            }
        }
        p{
            margin:0 0 10px 0;
            color:#6a6a6a;
        }
    }

    &__wrap {
        background-color: #fff;
        position: relative;
        max-width: 1100px;
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        flex-wrap: wrap;
        padding: 1vw;
        justify-content: center;
    }

    &__title {
        width: clamp(200px, 20vw, 300px);
        left: 50%;
        position: relative;
        transform: translate(-50%, 0);
    }

    &__title-col {
        @media screen and (max-width:480px) {
            gap: 0;
        }
    }

    &__eye-catching {
        position: relative;
        img {
            width: 100%;
            height: auto;
        }
    }

    &__writer-title {
        h3 {
            font-size: 1rem;
            font-weight: 100;
            @media screen and (max-width:450px) {
                font-size:0.8rem;
            }
        }
    }

    &__writer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
    }

    &__writer-name {
        font-size: 1.6rem;
        margin: 0;
        @media screen and (max-width:450px) {
            font-size:1.2rem;                
        }

        p {
            margin: 0;
        }
    }

    &__writer-image {
        img {
            width: 70px;
        }
    }

    &__writer-info {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1rem;
    }

    &__coming-soon {
        font-size: 3rem;
        font-weight: bold;
    }
    &__body-text{
        p{
            line-height:2;
        }
        
    }
}

.footer {
    hr {
        border-color: #ccc;
        margin: 0;
    }

    &__contact {
        line-height: 50px;
        margin: 0.6rem 0;

        &::before {
            content: " ";
            display: inline-block;
            background-image: url(../../images/mail-icon.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% auto;
            width: 30px;
            height: 30px;
            position: relative;
            top: 7px;
            margin-right: 10px;
        }
    }

    &__kyousan {
        div {
            @media screen and (max-width:1040px) {
                flex-direction: column;
                gap: 0.2rem;
                text-align: center;
            }

            gap:2rem;
            flex-wrap: wrap;

            p {
                &:first-child {
                    width: 60px;
                    text-align: justify;
                    text-align-last: justify;
                }
            }
        }

        @media screen and (max-width:400px) {}

    }

    &__event-list {
        font-size: 1.2rem;
        list-style: none;
        padding: 0;
        position: relative;

        @media screen and (max-width:400px) {
            font-size: 1rem;
        }

        li {
            padding-left: 1.5rem;
            line-height: 1.4;

            &::before {
                content: "〉";
                font-size: 1rem;
                position: absolute;
                left: 0px;
            }

        }
    }

    &__link-list {
        justify-content: center;
        font-size: 1rem;
        gap: 0;

        @media screen and (max-width:970px) {
            flex-direction: column;
            align-items: start !important;
        }

        p {
            padding-left: 1.5rem;
            line-height: 1.4;
            position: relative;

            &::before {
                content: "〉";
                font-size: 1rem;
                position: absolute;
                left: 0px;
            }
        }

        li {
            line-height: 1.4;
        }
    }

    &__wrap {
        max-width: 1100px;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 20px;
    }
}

.form {
    background-color: #F9F9F9;
    display: block;
    padding: 2rem 1rem;
    border-radius: 20px;
    max-width: 350px;

    h2 {
        font-size: 1.2rem;
        margin: 0;
        text-align: center;
        margin-bottom: 30px;
        margin-top: -10px;
    }

    &__step1 {}

    &__checkbox-list {
        height: 200px;
        overflow: scroll;
        border: 1px solid #C8C8C8;
        background-color: #F1F6FB;
        padding: 0.8rem 1rem;

    }

    &__checkbox-item {
        margin: 10px 0;

        p {
            display: inline;
            margin: 0 10px !important;
            position: relative;
            top: -3px;
        }
    }

    &__checkbox-answer {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin-top: 1rem;

        span {
            padding: 5px 10px;
            background-color: #C8C8C8;
            color: #000;
            border-radius: 10px;
        }
    }

    &__input-wrap {
        font-size: 0.8rem;
        margin-bottom: 10px;

        label {
            display: block;
            color: #000;
        }

        input {
            padding: 0.8rem 1rem;
            border-radius: 5px;
            border: 1px solid #C8C8C8;
            background-color: #F1F6FB;
            width: calc(100% - 2rem);

            &::placeholder {
                color: rgba(0, 0, 0, 0.3);
                /* 薄い黒色 */
            }
        }

        input[type="checkbox"] {
            width: auto;
        }

        select {
            padding: 0.8rem 1rem;
            border-radius: 5px;
            width: calc(100%);
            border: 1px solid #C8C8C8;
            background-color: #F1F6FB;
        }

        textarea {
            min-height: 100px;
            padding: 0.8rem 1rem;
            border-radius: 5px;
            width: calc(100% - 2rem);
            border: 1px solid #C8C8C8;
            background-color: #F1F6FB;

            &::placeholder {
                color: rgba(0, 0, 0, 0.3);
                /* 薄い黒色 */
            }
        }

        select {
            option {
                position: relative;
                padding: 5px;
                border-radius: 5px;
                margin: 10px 0;

                &:focus {
                    outline: none;
                }

                &:checked {
                    background-color: #2CD25E;
                    color: #fff;

                    &::before {
                        content: "✓";
                        position: absolute;
                        left: -15px;
                        color: #2CD25E;
                    }
                }
            }
        }

    }

    p {
        margin: 0;
    }

    &__progress-bar {
        transition: 1s;
    }

    &__progress {
        display: inline-block;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        margin-bottom: 20px;
    }

    &__progress-table {
        display: inline-block;
        transform: rotate(-90deg);
    }

    &__step-title {
        position: absolute;
        display: inline-block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #252525;

        h4 {
            text-align: center;
            font-size: 18px;
            font-family: Noto Sans;
            font-weight: 700;
            margin: 0;
            position: relative;
            bottom: -15px;
        }

    }

    &__next-button {
        padding: 15px 40px;
        border-radius: 30px;
        background-color: #2CD25E;
        border: none;
        color: #fff;
        font-weight: bold;

        &::after {
            content: "　";
            display: inline-block;
            background: url(../../images/form-button-arrow.svg);
            background-repeat: no-repeat;
            background-size: 100% auto;
            width: 20px;
            height: 20px;
            margin-left: 5px;
            position: relative;
            top: 1px;
        }
    }

    &__prev-button {
        padding: 15px 40px;
        border-radius: 30px;
        background-color: #DCDCDC;
        border: none;
        color: #828181;
        font-weight: bold;
    }

    &__step-no {
        position: relative;

        hr {
            width: 30px;
            transform: rotate(-45deg);
            border-bottom: 2px solid #000;
            position: relative;
        }

        p {
            margin: 0;
            padding: 0;
            font-size: 1.2rem;
            display: inline-block;
            position: relative;
            font-weight: bold;
            transform: translate(-50%, 0);

            &:nth-child(1) {
                bottom: -15px;
                left: calc(50% - 10px);
            }

            &:nth-child(3) {
                top: -15px;
                left: calc(50% + 10px);
            }
        }
    }

    &__buttons {
        margin-top: 40px;
        display: flex;
        justify-content: space-around;

    }

    &__image-preview {
        img {
            width: 100%;
        }

    }

    svg {
        position: relative;
        width: 150px;
        height: 150px;
    }

    circle {
        position: relative;
        fill: none;
        stroke-width: 10;
        stroke: #f3f3f3;
        stroke-dasharray: 440;
        stroke-dashoffset: 0;
        stroke-linecap: round;
    }

    circle.line {

        stroke: #2CD35F;
        transition: 0.5s;
    }

    &__red-button {
        background-color: #a72424 !important;
        color: #fff;
        font-weight: bold;
    }

    &__home-button {
        background-color: #C8C8C8;
        color: #828181;

        &::after {
            display: none;
        }
    }

    &__no-arrow {
        &::after {
            display: none !important;
        }

    }

}

#step2 {
    #other {
        min-height: 25px;
        height: 25px;
    }
}

#step3 {
    input {
        background-color: #C8C8C8;
    }

    select {
        background-color: #C8C8C8;

        option {}
    }

    textarea {
        background-color: #C8C8C8;
    }

}



.input-none {
    pointer-events: none;
}

#step6 {
    input {
        background-color: #C8C8C8;
    }

    input[type="submit"] {
        background-color: #a72424 !important;
        color: #fff;
    }

    select {
        background-color: #C8C8C8;

        option {}
    }

    textarea {
        background-color: #C8C8C8;
    }
}

.errortable {
    border: #ff0000 2px solid !important;
}

#step7 {
    .buttons {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        margin: 10px 0;
    }

    .thanks {
        text-align: center;
        font-weight: bold;
    }

    .input-mail {
        color: #a72424;
        display: block;
        padding: 1rem;
        width: calc(100% - 2rem);
        border-radius: 10px;
        text-align: center;
        border: solid 1px #a72424;
    }

    .other {
        color: #828181;
        font-size: 0.8rem;
    }
}

.page-photosenryuu {
    &__footer-kyousan {
        margin-top: 2rem;
    }

    &__whats {
        position: relative;
        left: -12px;
        font-size: 25px;

        @media screen and (max-width:450px) {
            font-size: 19px;

        }

    }

    &__explanation {
        line-height: 1.8;
    }

    &__notes {
        list-style-type: circle;
    }

    &__application-button {
        position: relative;
        z-index: 99;
        width: 100%;
        height: 200px;
        overflow: hidden;
        margin-top: -100px;
        margin-bottom: -35px;

        img {
            width: 200px;
            position: absolute;
            left: calc(50% + 250px);
            transform: -50%, 0;

            @media screen and (max-width:800px) {
                width: 150px;
                position: absolute;
                right: -20px;
                transform: initial;
                left: auto;
            }
        }
    }

    &__wrap {
        max-width: 1000px;
        width: 100%;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 1rem 1rem;
    }

    &__main-image {
        width: 95% !important;
    }

    &__title {
        z-index: 10;

        img {
            width: clamp(250px, 25vw, 500px);
            margin-top: -100px;
            position: relative;
            z-index: 1000;
            left: 50%;
            transform: translate(-50%, 0);

            @media screen and (max-width:450px) {
                margin-top: -50px;

            }
        }
    }

    &__theme {
        border: 2px solid #251E1C;
        display: inline-block;
        padding: 1rem clamp(10px, 4vw, 50px);
        border-radius: 1rem;
        position: relative;
        line-height: 1.6;
        left: 50%;
        transform: translate(-50%, 0);
        margin-bottom: 2rem;

        &::before {
            content: "　";
            display: block;
            height: 50px;
            width: 50px;
            background-color: #fff;
            position: absolute;
            left: -25px;
            top: -25px;
        }

        &::after {
            content: "　";
            display: block;
            height: 50px;
            width: 50px;
            background-color: #fff;
            position: absolute;
            right: -25px;
            bottom: -25px;
        }

        h2 {
            margin: 0;
            padding: 0;
        }

        p {
            font-weight: bold;
            letter-spacing: -3px;
            text-underline-offset: 10px;
            font-size: clamp(2rem, 5vw, 3rem) !important;
            margin: 0;
        }
    }

    &__kyousan-title {
        width: 100%;
        position: relative;

        &::before {
            content: " ";
            width: 100%;
            height: 1px;
            display: block;
            background-color: #E73820;
            position: absolute;
            top: 2rem;
        }

        h3 {
            color: #E73820;
            z-index: 10;
            background-color: #fff;
            position: relative;
            display: inline-block;
            padding: 1rem;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    &__gaiyou {
        @media screen and (max-width:450px) {
            flex-direction: column;
            justify-content: flex-start !important;
            align-items: start;
        }

        font-size:1.6rem;
        margin:1rem;

        p {
            &:nth-child(1) {
                color: #E73820;

            }

            code {
                font-size: 0.8rem;
            }

            left:0;
        }
    }

    &__application-period {
        line-height: 1;

    }

    &__footer-kyousan {
        margin-top: 2rem;

        position: relative;

        .is-layout-flex {

            @media screen and (max-width:450px) {
                flex-direction: column;
                margin-top: 1rem;

                .is-layout-flex {
                    margin-top: 0;
                }
            }
        }
    }


    ul {
        padding: 0 0 0 24px;
    }

    &__form-button {
        a {
            padding: 1rem 4rem;
        }
    }

    &__limit {
        padding: 1rem;
    }

    &__red-frame {
        padding: 1rem 3rem;
        border: 1px solid red;
        display: inline-block;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);

        @media screen and (max-width:600px) {
            br {
                display: none;
            }

            text-align: left !important;

        }
    }

    &__list-center {
        display: inline-block;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
    }
    
    &__announcement{
        padding:4rem 0;
        margin:4rem 0;
    }
    &__announcement-wrap{
        width: 100%;

    }
    &__announcement-article{    
        max-width: 800px;
        width: 100%;
        padding:1rem;
        container-type: inline-size;
    }

    &__announcement-dt{    
        border:2px solid #000;
        padding: 5px 15px !important;
    }

    &__announcement-article-sakuhin{
        gap:0em 0.5em;
        font-size:21px;
        font-weight: bold;
        @container (min-width:500px){
            gap:1.5rem;
            font-size:26px;
            flex-direction:row;
        }

        @container (min-width:767px){
            font-size:42px;
        }
    }
    &__announcement-tilte{
        font-weight: bold;
        font-size:2rem;

    }
    &__announcement-comment{
        margin-top:2rem;
        p{
            margin: 0 !important;
            line-height: 1.6;
        }
    }
    &__announcement-article-wrap{
        width: 100%;
        max-width: 1200px;
    }

    &__announcement-article-kyousan{
        width: calc(100% - 2rem);
        padding:0.5rem 1rem!important;
        border-radius: 1rem;
        code{
            font-size:0.8em;
            position: relative;
            top:-0.1rem;
        }
    }

    &__announcement-kongo{
        container-type: inline-size;
        padding:1.5rem;
        width: 100%;
    }
    &__announcement-kongo-wrap{
        border:2px solid #000;
        font-size:1.2rem;
        position: relative;
        padding:2rem 1rem;
        max-width: 850px;
        @container (min-width:460px){
            font-size:1.6rem;
            padding:2rem 4rem;
        }
    }
    &__announcement-kongo-title-wrap{
        position: absolute;
        top:-1em;
        display: inline-block;
        left:50%;
        transform: translate(-50%,0);
        background-color: #F9F4EE;
        padding:0 0.6em;
    }
    &__announcement-kongo-title{
        font-size:1em;
        width: 100%;
        display: inline-block;
        text-wrap: nowrap;
        margin:0;
    }
    &__announcement-kongo-text-wrap{
        font-size:0.8em;
    }

    &__announcement-title{
        font-size:1.6rem;
        font-weight: bold;
        @media screen and (min-width:768px) {
            font-size:2.2rem;
        }

        @media screen and (min-width:1024px) {
            font-size:2.8rem;
        }
    }
    &__thanks{
        gap:0;
        p{
            font-size:1.8rem;
            @media screen and (min-width:460px) {
                font-size:2.4rem;
            }
        code{
            display: inline-block;
        }
    }
    }
}




#page-photosenryuu {
    .form {
        left: 50%;
        transform: translate(-50%, 0);
        position: relative;
        &__wrap {
            width: 100%;
            max-width: 450px;
            position: fixed;
            z-index: 9999;
            background-color: #fff;
            display: inline-block;
            top: 0;
            right: -450px;
            border-radius: 0;
            overflow-y: scroll;
            height: 100%;
            transition: 1s;

            &.open {
                width: 100%;
                max-width: 450px;
                position: fixed;
                z-index: 9999;
                background-color: #fff;
                display: inline-block;
                top: 0;
                right: 0;
                border-radius: 0;
                overflow-y: scroll;
                height: 100%;
            }
        }

        &__crose-button {
            cursor: pointer;
            background-color: #000;
            width: 50px;
            height: 50px;
            position: absolute;
            right: 0.8rem;
            top: 0.8rem;
            border-radius: 50%;
            color: #fff;
            font-size: 2rem;
            text-align: center;
            line-height: 50px;
        }
    }
}

.privacypolicy {
    &__wrap {
        max-width: 1000px;
        width: 100%;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

.onepoint {
    position: relative;

    &::before {
        display: block;
        content: " ";
        position: absolute;
        height: 100%;
        background-image: url(../../images/onepoint-left.png);
        width: 150px;
        background-repeat: repeat-y;
        background-size: 100% auto;
        left: 1vw;

        @media screen and (max-width:1350px) {
            display: none;
        }
    }

    &::after {
        display: block;
        content: " ";
        position: absolute;
        height: 100%;
        background-image: url(../../images/onepoint-right.png);
        width: 150px;
        background-repeat: repeat-y;
        background-size: 100% auto;
        right: 1vw;
        top: 0;

        @media screen and (max-width:1350px) {
            display: none;
        }
    }

    &__data-details {
        line-height: 1.4rem;

        ul {
            margin: 0 0 1rem 0;

            li {
                line-height: 1.2rem;
            }
        }

        p {
            line-height: 1.2rem;
            text-underline-offset: 10px;
            margin: 0;
        }
    }

}

.info {
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);

    h2 {
        text-align: center;
    }
}

.footer-form {
    position: fixed;
    width: calc(100% - 20px);
    max-width: 600px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    display: none;

    .error {
        margin: 0 0 0.5em 0;
    }

    &.open {
        display: block;
    }

    h3 {
        margin: 0.2em;
        text-align: center;
        font-size: 1.6rem;
    }

    &__wrap {
        position: relative;
        display: block;
        width: calc(100% - 4em);
        padding: 1em 2em;
        background: #fff;
        border-radius: 10px;
    }

    &__success {
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100% - 2em);
        background-color: #fff;
        border-radius: 10px;
        width: calc(100% - 2em);
        z-index: 999;

    }

    &__success-wrap {
        display: inline-block;
        position: relative;
        top: calc(50% - 50px);
        transform: translate(0, -50%);
    }

    &__form {
        label {
            margin: 0;
        }

        input {
            display: block;
            width: calc(100% - 2em);
            padding: 1em;
            margin: 0 0 1em 0;
        }

        textarea {
            width: calc(100% - 2em);
            display: block;
            padding: 1em;
            height: 150px;
            margin: 0 0 1em 0;
        }

        button {
            display: inline-block;
            position: relative;
            left: 50%;
            transform: translate(-50%, 0);
            padding: 1em 3em;
            margin-top: 20px;
            margin: 1em 0;
            border: none;
            background-color: #252525;
            color: #fff;
            font-size: 1rem;
            font-weight: bold;
            border-radius: 30px;
        }
    }

    &__close-button {
        border: 0;
        background-color: #252525;
        border-radius: 50%;
        color: #fff;
        padding: 1rem;
        font-size: 2rem;
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 20px;
        position: absolute;
        right: .5em;
        top: .5em;
        z-index: 1002;
    }

    &__next {
        padding: 1em 2em;
        background-color: #251E1C;
        border-radius: 4em;
        color: #fff;
        font-weight: bold;
        border: none;
        display: block;
        min-width: 200px;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
    }

    &__close {
        padding: 1em 2em;
        background-color: #251E1C;
        border-radius: 4em;
        color: #fff;
        font-weight: bold;
        border: none;
        display: block;
        min-width: 200px;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        margin-top: 1em;

    }
}

#footerContact {
    cursor: pointer;
}

.success-message {
    text-align: center;
    padding: 1rem;
    color: red;
    border: 1px solid red;
}

#search-results {
    margin-bottom: 2em;

    article {
        border-bottom: 3px dotted #000;
        max-width: 1000px;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        margin-bottom: 2em;
        padding: 1em;

        button {
            background-color: #727171;
            padding: 0.4em 1em;
            text-align: center;
            border: none;
            color: #fff;
            border-radius: 0.4em;
            width: 100%;
        }
    }
}
.display-none{
    display: none;
}
.health-project {
    &__none{
        text-align: center;
    }
    &__article-body {
        display: flex;

        @media screen and (max-width:600px) {
            flex-direction: column;
        }
    }
    &__new{
        background-color: red;
        padding: 0.2em 1em;
        border-radius: 0.4em;
        color:#fff;
        margin-right:1em;
        font-weight: bold;
    }

    &__end{
        background-color: #ccc;
        padding: 0.2em 1em;
        border-radius: 0.4em;
        color:#fff;
        margin-right:1em;
        font-weight: bold;
        span{
        font-size:0.8em;
        }
    }
    &__button {
        position: absolute;
        bottom: 20px;
        width: 100%;
    }

    &__hit {
        font-size: 1rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 2em;
    }

    &__search-results-list {
        text-align: center;
        font-size: 1.6rem;
        margin-top: 4rem;
        border-bottom: 1px solid #8CC63F;
        display: inline-block;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
    }

    &__article-left {
        width: 70%;
        @media screen and (max-width:600px) {
            width: 100%;
        }


        h2 {
            margin: 0.5em 0;
        }

        dl {
            margin: 0.5em 0;
        }

        dt {
            border: 1px solid;
            border-radius: 0.6em;
            display: inline-block;
            padding: 0em 0.5em;
            min-width: 50px;
            text-align: center;
            vertical-align: top;
        }

        dd {
            display: inline-block;
            margin-left: 15px;
            font-size: 1.4rem;
            width: calc(100% - 121px);
            position: relative;
            top: 0;
            line-height: 1.4;
            @media screen and (max-width:600px) {
                width: 100%;
                margin-left: 0;
                margin-top:5px;
            }
        }
    }
    &__content{
        @media screen and (max-width:600px) {
            font-size:1.2rem!important;
        }
    }
    &__place1 {
        padding: 0.2em 0.5em;
        border: 1px solid;
        border-radius: 0.4em;
    }

    &__place2 {
        padding: 0.2em 0.5em;
        margin-left: 1em;
        border-radius: 0.4em;
    }

    &__article-right {
        width: 30%;
        position: relative;
        padding-bottom: 50px;
        @media screen and (max-width:600px) {
            width: 100%;
        }

    }

    &__form-buttons {
        display: inline-block;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        max-width: 600px;
        width: 100%;
    }

    &__form {
        background-color: #F9F4EE;
        padding: 4em 1em;

        h3 {
            text-align: center;

            img {
                max-width: 600px;
                width: 100%;
            }
        }

        p {
            text-align: center;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            gap: 2em;
            @media screen and (max-width:600px) {
                gap:1em;
            }
            justify-content: center;
            display: flex;
            line-height: 1;
            margin:2em 0　0;
            li {
                font-size: 1.6rem;
            }
        }

        input[type=checkbox] {
            transform: scale(2);
            position: relative;
            top: -2px;
        }

        input[type=text] {
            font-size: 1.4em;
            padding: 0.5em 1em;
            border-radius: 1em;
            width: calc(100% - 2em);
        }


        input[type=button] {
            background-color: none;
            background-image: url(../../images/search-button.svg);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: auto 100%;
            position: absolute;
            top: 0;
            right: 10px;
            height: 40px;
            width: 60px;
            background-color: inherit;
            top: 50%;
            transform: translate(0, -50%);
            border: none;
            border-left: 1px solid #000;
        }

    }

    &__article-img {
        width: 100%;

        img {
            width: 100%;
        }
    }
}

.youtube {
    width: 100%;
    aspect-ratio: 16 / 9;
  }
  .youtube iframe {
    width: 100%;
    height: 100%;
  }