
.error {
  color: red;
}

.image-preview {
  width: 200px;
  height: auto;
  margin-top: 10px;
}

.image-preview img {
  max-width: 100%;
  height: auto;
}
